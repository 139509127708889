import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Pagination } from '@mui/material';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './CustomPagination.module.scss';

const useStyles = makeStyles({
    root: {
        margin: '15px 0px',
        '& .Mui-selected': {
            backgroundColor: '#ff6600',
            color: '#fff !important',
        },
        '& .MuiPaginationItem-root': {
            color: "#B0B3B7"
        },
        '& .Mui-disabled': {
            color: 'grey !important'
        },
        '& .MuiPagination-ul': {
            justifyContent: 'flex-end'
        }
    }
});

export default function CustomPagination({ count, showFirstButton, showLastButton, recordPerPage, changePage, className, defaultPage, page, totalRecords }) {
    const classes = useStyles();
    let recrdperPage = Math.ceil(count / recordPerPage);
    useEffect(() => {
        (async () => {



        })();
    }, []);

    return (
        <section className={styles.paginationSection}>
            <Container fluid>
                {/* <Row>
                    <Col md={12}> */}
                        <div className={styles.content}>
                            <div className="entries-amount">
                                <span>View</span>
                                <select className="select" onChange={totalRecords} defaultValue={recordPerPage}>
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                </select>
                                <span className="hide-mobile">Jobs Per Page</span>
                            </div>
                            <Pagination
                                disabled={count.length == 0}
                                className={classes.root + ' ' + className}
                                count={recrdperPage}
                                onChange={changePage}
                                showFirstButton={showFirstButton}
                                showLastButton={showLastButton}
                                defaultPage={defaultPage}
                                page={page}
                            />
                        </div>
                    {/* </Col>
                </Row> */}
            </Container>
        </section>
    )
}
