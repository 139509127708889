import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

//components
import Dashboard from '../components/Dashboard/Dashboard';
import Encryptedoffer from '../components/Encryptedjoboffer/Encryptedoffer';
import Login from '../components/Login/Login';
import Decryptjoboffer from '../components/Decryptjoboffer/Decryptjoboffer';
import OfferAccessDetails from '../components/OfferAccessDetails/OfferAccessDetails';
import Offerempty from '../components/Offerempty/OfferEmpty';
import ProtectedRoute from './ProtectedRoute';



function RouterComponent() {
    return (
        <Router>
           
            <Routes>
                <Route path='/' element={<Login />}/>
                <Route path='/dashboard' element={<Dashboard />} />
                <Route path='/login' element={<Login />} />
                <Route path='/encryptedoffer' element={<Encryptedoffer />} />
                <Route path='/decryptjoboffer' element={<Decryptjoboffer/>}/>
                <Route path='/offeraccessdetails' element={<OfferAccessDetails/>}/>
                <Route path='/offerempty' element={<Offerempty/>}/>
            </Routes>
        </Router>
    );
}

export default RouterComponent;