import React from "react";
import styles from '../Decryptjoboffer/Decryptjoboffer.module.scss';
import { Col, Container, FormGroup, Row } from 'react-bootstrap';
import CompanyImg from '../../assets/images/logoNew.svg';
import Decryptimage from '../../assets/images/dcrypt.svg';
import JobDetail from '../../assets/images/jobDetail.svg';
import offerExp from '../../assets/images/offerExpair.svg';
import officeInfo from '../../assets/images/officeInfo.svg';
import salBon from '../../assets/images/salBon.svg';


const EncryptComponent = (props) => {
    const { candidateName, candidateNumber, candidateEmail, candidateCity, candidateState, candidateAddress, item } = props;

    return (
        <div className={styles.scrollbarContainer}>
            <div className={styles.scrollContent}>
                {/* <img className={styles.mediaQuery} src={CompanyImg} alt='' /> */}
                <div className={styles.perDetail}>
                    <h3><span>Name:</span> {candidateName}</h3>
                    <h3><span>jobId:</span> {item.jobId}</h3>
                    <h3><span>Phone number:</span>{candidateNumber}</h3>
                    <h3><span>Email Id:</span> {candidateEmail}</h3>
                    <div className={styles.addreSec}>
                        <h3><span>City:</span> {candidateCity} ({candidateState})</h3>
                        <h3><span>Address:</span> {candidateAddress}</h3>
                    </div>
                    <p>We have all really enjoyed speaking with you and getting to know you over the interactions we had.</p>
                    <p>We are impressed with your background and approach and would love to formally offer you a position at</p>
                    <div className={styles.streetSec}>
                        <h3>ï¿½ï¿½Fï¿½?ï¿½o $ï¿½~ï¿½ï¿½Qï¿½Cï¿½ï¿½ï¿½ï¿½5%</h3>
                        <h3>ï¿½#+ï¿½eï¿½6ï¿½ï¿½ï¿½ï¿½ï¿½</h3>
                    </div>
                </div>

                <div className={styles.jobDetails}>
                    <h2><img src={JobDetail} alt='' />Job Details</h2>
                    <div className={styles.detailNew}>
                        <h3><span>Position name:</span> ï¿½#+ï¿½eï¿½6ï¿½ï¿½ï¿½ï¿½ï¿½</h3>
                        <h3><span>Job title:</span> ï¿½ï¿½'ï¿½Gï¿½Dyï¿½ï¿½ï¿½ï¿½</h3>
                        <h3><span>Specialzations: </span>
                            <ul>
                                <li>ï¿½ï¿½'ï¿½Gï¿½Dyï¿½ï¿½ï¿½ï¿½</li>
                                <li>ï¿½ï¿½Gï¿½Dyï¿½ï¿½ï¿½ï¿½</li>
                                <li>Gï¿½Dyï¿½ï¿½ï¿½ï¿½</li>
                                <li>ï¿½ï¿½\uï¿½(*ï¿½ï¿½</li>
                                <li>ï¿½ï¿½\uï¿½(*ï¿½ï¿½</li>
                            </ul>
                        </h3>
                        <h3><span>Job Location:</span>ï¿½ï¿½\uï¿½(*ï¿½ï¿½</h3>
                        <h3><span>Date of joining:</span>ï¿½eï¿½6ï¿½ï¿½ï¿½ï¿½ï¿½</h3>
                        <h3><span>Notice period: </span>ï¿½#+ï¿½e</h3>
                    </div>
                </div>

                <div className={styles.offerExp}>
                    <h2><img src={offerExp} alt='' />Offer letter expire</h2>
                    <div className={styles.detailNew}>
                        <h3><span>Expire date:</span> ï¿½ILÍ³)eï¿½ï¿½@3</h3>
                        <h3><span>Expire time:</span> ï¿½eï¿½6ï¿½ï¿½ï¿½ï¿½ï¿½</h3>
                    </div>
                </div>

                <div className={styles.salBon}>
                    <h2><img src={salBon} alt='' />Salary & Bonus</h2>
                    <div className={styles.detailNew}>
                        <h3><span>Salary (per month):</span>ï¿½eï¿½6ï¿½ï¿½ï¿½ï¿½ï¿½</h3>
                        <h3><span>Bonus period:</span>8ï¿½ï¿½WNdï¿½$ï¿½ï¿½</h3>
                        <h3><span>Bonus amount:</span> ï¿½ï¿½Lï¿½ï¿½ </h3>
                    </div>
                </div>

                <div className={styles.offiecInfor}>
                    <h2><img src={officeInfo} alt='' />Office Information</h2>
                    <div className={styles.detailNew}>
                        <h3><span>Job shift:</span>?ï¿½o $ï¿½~ï¿½</h3>
                        <h3><span>Job type:</span> ï¿½ILÍ³)eï¿½ï¿½@3</h3>
                        <h3><span>Work from:</span> ï¿½#+ï¿½eï¿½6 </h3>
                        <h3><span>Shift timing:</span> +ï¿½eï¿½6</h3>
                        <h3><span>Language:</span> ï¿½#+ï¿½ï¿½ILÍ³</h3>
                        {/* <h3><span>Working days:</span>{item.workingDays.monday === true ? 'Monday | ' : ''} 
                    {item.workingDays.tuesday === true ? 'Tuesday | ' : ''}  {item.workingDays.wednesday === true ? 'Wednesday | ' : ''}  
                    {item.workingDays.thursday === true ? 'Thursday | ' : ''}  {item.workingDays.monday === true ? 'Friday' : ''} 
                    {item.workingDays.saturday === true ? ' | Saturday' : ''} {item.workingDays.sunday === true ? ' | Sunday' : ''}</h3> */}
                        <h3><span>Working days:</span>ï¿½eï¿½6ï¿½ï¿½ï¿½ï¿½ï¿½</h3>
                        <h3><span>Requirements:</span> Gï¿½Dyï¿½ï¿½ï¿½ï¿½</h3>
                        <h3><span>Information:</span> ?ï¿½o $ï¿½~ï¿½ </h3>
                        <div className={styles.newCont}>
                            <p>Please note that this is only a provisional offer of employment/professional services and is not being considered as an appointment letter.</p>
                            <p>An appointment letter would be issued to you on your accepting this provisional offer.</p>
                            <p className={styles.weLook}>We look forward to welcoming you on board.</p>
                        </div>
                        <div className={styles.mainAdd}>
                            {/* <h3>{item.employerName} - {item.representativeRole}</h3> */}
                            <h3>Mï¿½2yï¿½ï¿½ï¿½</h3>
                            <h3>fï¿½ï¿½ï¿½ï¿½ï¿½</h3>
                            <h3>xï¿½ï¿½3ï¿½ï¿½</h3>
                        </div>
                    </div>
                </div>

                <div className={styles.btnAll}>

                </div>
            </div>
        </div>
    )

}

export { EncryptComponent }