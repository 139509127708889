import { style } from '@mui/system';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { showLoader, hideLoader } from '../../helpers/index';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from "react-router-dom";
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import NoDataFound from '../common/NoDataFound/NoDataFound';
import styles from './Encryptedoffer.module.scss';
import JobDetail from '../../assets/images/jobDetail.svg';
import removeIcon from '../../assets/images/removed.png';
import offerExp from '../../assets/images/offerExpair.svg';
import officeInfo from '../../assets/images/officeInfo.svg'
import CompanyImg from '../../assets/images/logoNew.svg'
import salBon from '../../assets/images/salBon.svg';
import EncryptUnlock from '../../assets/images/EncryptUnlock.svg';
import { getJobOffers, grantToShowJobOfferDetailsWeb } from '../../services/api/common';
import { setCookie, getCookie, clearCookie } from "../../helpers/index";
import Offerempty from '../Offerempty/OfferEmpty'
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs() {
    const [value, setValue] = React.useState(getCookie("selectedJOboffer") ? parseInt(getCookie("selectedJOboffer")) : 0);
    const [jobOfferData, setjobOfferData] = useState([]);
    const [jobId, setjobId] = useState();
    const [jobofferId, setjobofferId] = useState();
    const [employerId, setemployerId] = useState();
    const [candidateName, setcandidateName] = useState();
    const [candidateId, setcandidateId] = useState();
    const [candidateNumber, setcandidateNumber] = useState();
    const [candidateEmail, setcandidateEmail] = useState();
    const [candidateCity, setcandidateCity] = useState();
    const [candidateState, setcandidateState] = useState();
    const [candidateAddress, setcandidateAddress] = useState();
    const [granted, setgranted] = useState('');
    let location = useLocation()
    let navigate = useNavigate();
    const isFromDecrypt = location.state && location.state.fromDecrypt



    useEffect(() => {
        if (isFromDecrypt) {
            location = {};
        }

        if (location.state && location.state.employerId) {
            setgranted(false);
        }
        candidateProfile()
    }, [granted])

    const candidateProfile = async () => {
        try {
            showLoader();
            const {
                data
            } = await getJobOffers();
            if (data.data && data.data._id) {
                setcandidateId(data.data._id)
                setcandidateName(data.data.fullName)
                setcandidateNumber(data.data.whatsappMobile)
                setcandidateEmail(data.data.email)
                setcandidateCity(data.data.city)
                setcandidateState(data.data.state)
                setcandidateAddress(data.data.fullAddress)
                if (data.data.jobOfferData.length !== 0) {
                    setjobOfferData(data.data.jobOfferData)
                    setjobId(data.data.jobOfferData[0].jobId)
                    setjobofferId(data.data.jobOfferData[0]._id)
                    setemployerId(data.data.jobOfferData[0].employerId)
                    //    toast.success("Job offers fetched successfully");
                    //clearCookie("selectedJOboffer")
                } else {
                    toast.error("No job offer for you");
                }
            } else {
                toast.error("Failed");
            }
            hideLoader();
        } catch (error) {
            hideLoader();
            if (error && error.data && error.data.appStatus && error.data.appStatus === 872) {
                window.location.href = "/";
            } else {
                toast.error("Failed");
            }
        }
    }


    const handleChange = (event, newValue) => {
        setValue(newValue);
        setjobId(jobOfferData[newValue].jobId)
        setjobofferId(jobOfferData[newValue]._id)
        setemployerId(jobOfferData[newValue].employerId)


    };

    const setselectedVal = () => {
        // setCookie("selectedJOboffer", value)
        navigate('/offeraccessdetails', {
            state: { jobId: jobId, jobofferId: jobofferId, employerId: employerId, candidateId: candidateId, value: value }
        })

    }


    useEffect(() => {
        async function fetchData() {
            if (location.state) {

                try {
                    showLoader();
                    //if (getCookie("selectedJOboffer")) {
                    const interval = setInterval(async () => {
                        const {
                            data: {
                                data, status
                            }
                        } = await grantToShowJobOfferDetailsWeb(location.state);
                        console.log(data)
                        if (data.viewAccessGranted === false) {
                            console.log(new Date().toLocaleString())
                            if (isFromDecrypt) {
                                setgranted(false);
                            } else {
                                setgranted(false);
                            }

                        }
                        else if (data.viewAccessGranted === true) {
                            console.log(data.viewAccessGranted)
                            clearInterval(interval);
                            //clearCookie('selectedJOboffer')
                            navigate('/decryptjoboffer', {
                                state: {
                                    whatsappIdentification: location.state.whatsappIdentification,
                                    sessionUniqueID: location.state.sessionUniqueID, candidateId: location.state.candidateId,
                                    jobId: location.state.jobId, viewedAt: data.viewedAt,
                                    jobOfferId: location.state.jobOfferId,
                                    employerId: location.state.employerId
                                }
                            })

                        }


                        //    toast.success("Job offers fetched successfully");
                        //     hideLoader();

                    }, 10000);
                    //}
                }
                catch (error) {
                    hideLoader();
                    toast.error("Failed");
                }


            }
        }
        fetchData();
    }, [location.state])

    return (
        <div className={styles.bodyNew}>
            <section className={styles.headfixed} style={{ height: "90px", display: "none" }}>
                <Container fluid className={styles.newPad}>
                    <div className="row">
                        <Col md={12}>
                            <header className="styles.headerNavebar">
                                <Container>
                                    <div className={styles.navbar}>
                                        <a className={styles.navbarLogo} href>
                                            <img className={styles.mediaQuery} src={CompanyImg} alt='' />
                                        </a>
                                        {/* <ul className={styles.navbarLinks}>
                                            <li>
                                                <a className={styles.navbarLogo} href>
                                                    {granted === false &&
                                                        <h5>
                                                            Please wait for employers confirmation
                                                        </h5>
                                                    }
                                                </a>
                                            </li>
                                            <li>
                                                {(granted !== false && jobOfferData.length > 0) &&
                                                    <Link onClick={() => setselectedVal()} to="/offeraccessdetails" state={{ jobId: jobId, jobofferId: jobofferId, employerId: employerId, candidateId: candidateId }}
                                                        className={styles.showbtn}
                                                    >
                                                        Show Job Offer
                                                    </Link>
                                                }
                                            </li>
                                        </ul> */}
                                    </div>
                                </Container>
                            </header>
                        </Col>
                    </div>
                </Container>
            </section>


            {jobOfferData.length !== 0 && <section className={styles.jobOfferTab}>
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <div className={styles.offerdis}>
                                <Box sx={{ width: '100%' }}>
                                    <Box className={styles.tabCon} sx={{ borderBottom: 1, borderColor: 'divider' }}>

                                        <Tabs className={styles.tabView} value={value} onChange={handleChange} aria-label="basic tabs example"
                                            variant="scrollable"
                                            scrollButtons
                                            allowScrollButtonsMobile>
                                            {jobOfferData.map((item, index) => {

                                                return (
                                                    <Tab
                                                        key={index}
                                                        className={`${styles.newList} ${value === index ? styles.newListActive : ""}`}
                                                        label={item.jobTitle} {...a11yProps(index)}
                                                        icon={parseInt(getCookie("selectedJOboffer")) === index ? <img style={{ height: "20px", width: "20px" }} rel="shortcut icon" src={EncryptUnlock} /> : ""}
                                                    />
                                                )

                                            })}
                                        </Tabs>

                                    </Box>
                                </Box>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>}
            {jobOfferData && jobOfferData.length > 0 ? jobOfferData.map((item, index) => {
                 const isRejectedAutoRevoked = item.offerStatus === "Rejected" && item.comments === "AutoRevoked";
                return <TabPanel
                    value={value}
                    index={index}
                    key={index}
                >
                    <section className={styles.headfixed} style={{ height: "90px", marginTop: "10px" }}>
                        <Container fluid className={styles.newPad}>
                            <div className="row">
                                <Col md={12}>
                                    <header className={styles.headerNavebar}>
                                        <Container fluid>
                                            <div className={styles.navbar}>
                                                <a className={styles.navbarLogo} href>
                                                    <img className={styles.mediaQuery} src={CompanyImg} alt='' />
                                                </a>
                                                <ul className={styles.navbarLinks}>
                                                    {(granted === false && !isFromDecrypt && parseInt(getCookie("selectedJOboffer")) === index) &&
                                                        <li>
                                                            <div className={styles.timerLock}>
                                                                <a className={styles.navbarLogo} href>
                                                                    <h5>Please wait for employers confirmation</h5>
                                                                </a>
                                                            </div>
                                                        </li>}
                                                    {(jobOfferData.length > 0 && (parseInt(getCookie("selectedJOboffer")) !== index) || isFromDecrypt) && 
                                                      !isRejectedAutoRevoked && (
                                                    <li>
                                                        
                                                        {/* <button className={styles.showbtn}>Show Job Offer</button> */}
                                                        <a className={styles.showbtn} style={{ textAlign: "center" }} href='javascript:void(0);' onClick={() => setselectedVal()}>
                                                            UNLOCK
                                                            <br />
                                                            <span>{item.jobTitle}</span>
                                                        </a>

                                                    </li>)}
                                                </ul>
                                            </div>
                                        </Container>
                                    </header>
                                </Col>
                            </div>
                        </Container>
                    </section>

                    <section className={styles.jobOffer}>
                        <Container>
                            <Row>
                                <Col md={12}>
                                    <div className={styles.offerdis}>
                                        <Box sx={{ width: '100%' }}>
                                            <section className={styles.headfixed} style={{ height: "90px", display: "none" }}>
                                                <Container fluid className={styles.newPad}>
                                                    <div className="row">
                                                        <Col md={12}>
                                                            <header className="styles.headerNavebar">
                                                                <Container>
                                                                    <div className={styles.navbar}>
                                                                        <a className={styles.navbarLogo} href>
                                                                            <img className={styles.mediaQuery} src={CompanyImg} alt='' />
                                                                        </a>
                                                                        <ul className={styles.navbarLinks}>
                                                                            <li>
                                                                                <a className={styles.navbarLogo} href>
                                                                                    {(granted === false && !isFromDecrypt && parseInt(getCookie("selectedJOboffer")) === index) &&
                                                                                        <h5>
                                                                                            Please wait for employers confirmation
                                                                                        </h5>
                                                                                    }
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                {/* <button className={styles.showbtn}>Show Job Offer</button> */}
                                                                                {(jobOfferData.length > 0 && parseInt(getCookie("selectedJOboffer")) !== index) &&
                                                                                  !isRejectedAutoRevoked && (
                                                                                    <a href='javascript:void(0);' onClick={() => setselectedVal()} className={styles.showbtn} style={{ textAlign: "center" }}>
                                                                                        UNLOCK
                                                                                        <br></br>
                                                                                        <span>{item.jobTitle}</span>
                                                                                    </a>
                                                                                    )}
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </Container>
                                                            </header>
                                                        </Col>
                                                    </div>
                                                </Container>
                                            </section>
                                            {item.offerStatus === "Rejected" && item.comments === "AutoRevoked" && (
                                                <div className={styles.overlaymessage}>
                                                    <img src={removeIcon}/>
                                                    <h3>We had to cancel your job offer because you didn't respond in time. Please get in touch with HR/Employer.</h3>
                                                </div>
                                            )}
                                            <div className={`${styles.scrollbarContainer} ${item.offerStatus === "Rejected" && item.comments === "AutoRevoked" ? styles.blurred : ''}`}>
                                            
                                                <div className={styles.scrollContent}>
                                                    {/* <img className={styles.mediaQuery} src={CompanyImg} alt='' /> */}
                                                    <div className={styles.perDetail}>
                                                        <h3><span>Name:</span> {candidateName}</h3>
                                                        <h3><span>jobId:</span> {item.jobId}</h3>
                                                        <h3><span>Phone number:</span>{candidateNumber}</h3>
                                                        <h3><span>Email Id:</span> {candidateEmail}</h3>
                                                        <div className={styles.addreSec}>
                                                            <h3><span>City:</span> {candidateCity} ({candidateState})</h3>
                                                            <h3><span>Address:</span> {candidateAddress}</h3>
                                                        </div>
                                                        <p>We have all really enjoyed speaking with you and getting to know you over the interactions we had.</p>
                                                        <p>We are impressed with your background and approach and would love to formally offer you a position at</p>
                                                        <div className={styles.streetSec}>
                                                            <h3>ï¿½ï¿½Fï¿½?ï¿½o $ï¿½~ï¿½ï¿½Qï¿½Cï¿½ï¿½ï¿½ï¿½5%</h3>
                                                            <h3>ï¿½#+ï¿½eï¿½6ï¿½ï¿½ï¿½ï¿½ï¿½</h3>
                                                        </div>
                                                    </div>

                                                    <div className={styles.jobDetails}>
                                                        <h2><img src={JobDetail} alt='' />Job Details</h2>
                                                        <div className={styles.detailNew}>
                                                            <h3><span>Position name:</span> ï¿½#+ï¿½eï¿½6ï¿½ï¿½ï¿½ï¿½ï¿½</h3>
                                                            <h3><span>Job title:</span> ï¿½ï¿½'ï¿½Gï¿½Dyï¿½ï¿½ï¿½ï¿½</h3>
                                                            <h3><span>Specialzations: </span>
                                                                <ul>
                                                                    <li>ï¿½ï¿½'ï¿½Gï¿½Dyï¿½ï¿½ï¿½ï¿½</li>
                                                                    <li>ï¿½ï¿½Gï¿½Dyï¿½ï¿½ï¿½ï¿½</li>
                                                                    <li>Gï¿½Dyï¿½ï¿½ï¿½ï¿½</li>
                                                                    <li>ï¿½ï¿½\uï¿½(*ï¿½ï¿½</li>
                                                                    <li>ï¿½ï¿½\uï¿½(*ï¿½ï¿½</li>
                                                                </ul>
                                                            </h3>
                                                            <h3><span>Job Location:</span>ï¿½ï¿½\uï¿½(*ï¿½ï¿½</h3>
                                                            <h3><span>Date of joining:</span>ï¿½eï¿½6ï¿½ï¿½ï¿½ï¿½ï¿½</h3>
                                                            <h3><span>Notice period: </span>ï¿½#+ï¿½e</h3>
                                                        </div>
                                                    </div>

                                                    <div className={styles.offerExp}>
                                                        <h2><img src={offerExp} alt='' />Offer letter expire</h2>
                                                        <div className={styles.detailNew}>
                                                            <h3><span>Expire date:</span> ï¿½ILÍ³)eï¿½ï¿½@3</h3>
                                                            <h3><span>Expire time:</span> ï¿½eï¿½6ï¿½ï¿½ï¿½ï¿½ï¿½</h3>
                                                        </div>
                                                    </div>

                                                    <div className={styles.salBon}>
                                                        <h2><img src={salBon} alt='' />Salary & Bonus</h2>
                                                        <div className={styles.detailNew}>
                                                            <h3><span>Salary (per month):</span>ï¿½eï¿½6ï¿½ï¿½ï¿½ï¿½ï¿½</h3>
                                                            <h3><span>Bonus period:</span>8ï¿½ï¿½WNdï¿½$ï¿½ï¿½</h3>
                                                            <h3><span>Bonus amount:</span> ï¿½ï¿½Lï¿½ï¿½ </h3>
                                                        </div>
                                                    </div>

                                                    <div className={styles.offiecInfor}>
                                                        <h2><img src={officeInfo} alt='' />Office Information</h2>
                                                        <div className={styles.detailNew}>
                                                            <h3><span>Job shift:</span>?ï¿½o $ï¿½~ï¿½</h3>
                                                            <h3><span>Job type:</span> ï¿½ILÍ³)eï¿½ï¿½@3</h3>
                                                            <h3><span>Work from:</span> ï¿½#+ï¿½eï¿½6 </h3>
                                                            <h3><span>Shift timing:</span> +ï¿½eï¿½6</h3>
                                                            <h3><span>Language:</span> ï¿½#+ï¿½ï¿½ILÍ³</h3>
                                                            {/* <h3><span>Working days:</span>{item.workingDays.monday === true ? 'Monday | ' : ''} 
                                                    {item.workingDays.tuesday === true ? 'Tuesday | ' : ''}  {item.workingDays.wednesday === true ? 'Wednesday | ' : ''}  
                                                    {item.workingDays.thursday === true ? 'Thursday | ' : ''}  {item.workingDays.monday === true ? 'Friday' : ''} 
                                                    {item.workingDays.saturday === true ? ' | Saturday' : ''} {item.workingDays.sunday === true ? ' | Sunday' : ''}</h3> */}
                                                            <h3><span>Working days:</span>ï¿½eï¿½6ï¿½ï¿½ï¿½ï¿½ï¿½</h3>
                                                            <h3><span>Requirements:</span> Gï¿½Dyï¿½ï¿½ï¿½ï¿½</h3>
                                                            <h3><span>Information:</span> ?ï¿½o $ï¿½~ï¿½ </h3>
                                                            <div className={styles.newCont}>
                                                                <p>Please note that this is only a provisional offer of employment/professional services and is not being considered as an appointment letter.</p>
                                                                <p>An appointment letter would be issued to you on your accepting this provisional offer.</p>
                                                                <p className={styles.weLook}>We look forward to welcoming you on board.</p>
                                                            </div>
                                                            <div className={styles.mainAdd}>
                                                                {/* <h3>{item.employerName} - {item.representativeRole}</h3> */}
                                                                <h3>Mï¿½2yï¿½ï¿½ï¿½</h3>
                                                                <h3>fï¿½ï¿½ï¿½ï¿½ï¿½</h3>
                                                                <h3>xï¿½ï¿½3ï¿½ï¿½</h3>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={styles.btnAll}>

                                                    </div>
                                                </div>
                                            </div>
                                        </Box>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </TabPanel>
            }) :
                <Offerempty />
            }


            <section className={styles.footerHead}>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className={styles.footerPera}>
                                <ul>
                                    <li><a target="_blank" rel="noreferrer" href="https://www.jobsgaar.com/">Home</a></li>
                                    <li><a target="_blank" rel="noreferrer" href="https://www.jobsgaar.com/about_hindi">About Us</a></li>
                                    <li><a target="_blank" rel="noreferrer" href="https://jobsgaar.com/blog/">Blogs</a></li>
                                    <li><a target="_blank" rel="noreferrer" href="https://www.jobsgaar.com/contact">Contact Us</a></li>
                                </ul>
                                <p>Powered by  <a className="navbar__logo" rel="noreferrer" href="https://www.jobsgaar.com/"><img src={CompanyImg} alt={'sdfsd'} /></a></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div >
    );
}