import { style } from '@mui/system';
import React, { useState } from 'react';
import { Col, Container, FormGroup, Row } from 'react-bootstrap';

import styles from './Login.module.scss';

import LoginImage from '../../assets/images/indiaMap.png';
import CompanyImage from '../../assets/images/logo.svg';
import whatsappIcon from '../../assets/images/whatsappIcon.png';
import editIcon from '../../assets/images/edit.svg';
import { useForm } from "react-hook-form";
import { login } from '../../services/api/common';
import OtpInput from "react18-input-otp";
import { toast } from 'react-toastify';
import { showLoader, hideLoader, setCookie } from '../../helpers/index';
import { requestOtpForLogin, verifyOtpForLogin } from '../../services/api/otp';
import { useNavigate } from 'react-router-dom';
import { getProfile } from '../../services/api/common';

/**login functionality */
function Login() {

  const greeting = "greeting";
  const displayAction = false;
  const navigate = useNavigate();

  //state
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [mobileNumber, setMobileNumber] = useState();
  const [otpNumber, setOtpNumber] = useState();
  const [otpError, setOtpError] = useState(false);
  const [userDataForOtp, setUserDataForOtp] = useState({});

  let message = {
    required: 'Whatsapp number is required',
    pattern: 'Name should contain only numbers',
    maxLength: "Maximum allowed numbers 10"
  }

  const submit = async (submitData) => {
    try {
      showLoader();
      const {
        data: {
          data, status
        }
      } = await login(submitData);

      if (status == 200 && data.userType === "candidate") {
        sendOtp({ userId: data._id, type: data.userType, whatsappMobile: data.whatsappMobile });
        setUserDataForOtp({
          type: data.userType,
          whatsappMobile: data.whatsappMobile,
          userId: data._id
        });
      } else if (status == 200 && data.userType === "candidate") {
        toast.error("Candidate doesn't exist on this platform");
      } else {
        toast.error("failed");
      }
      hideLoader();
      // toast.success("success")
    } catch (errors) {
      hideLoader();
      toast.error("failed");
    }
  }

  const sendOtp = async (data) => {
    try {
      showLoader();
      let {
        data: {
          message, status
        }
      } = await requestOtpForLogin(data);
      setMobileNumber(data.whatsappMobile);
      setIsOtpSent(true);
      hideLoader();
      toast.success(message ? message : "Otp has sent successfully");
    } catch (errors) {
      hideLoader();
      toast.error("failed");
    }
  }

  const handleOtpChange = (data) => {
    setOtpNumber(data);
    data ? setOtpError(false) : setOtpError(true);
  }

  const otpSubmit = async () => {
    if (!otpNumber) {
      setOtpError(true);
      return;
    }
    try {
      let submitData = userDataForOtp;
      submitData.otp = otpNumber;
      submitData.deviceToken = "CandidatePortal";
      showLoader();
      let {
        data: {
          data
        }
      } = await verifyOtpForLogin(submitData);
      console.log(data)
      if (data.refreshToken) {
        hideLoader();
        toast.success(message ? message : "SignIn Successfull");
        setCookie("token", data.refreshToken);
        let candidateData = await getProfileData();
        localStorage.setItem("userData", JSON.stringify(candidateData))
        navigate('/encryptedoffer');
      }
    } catch (error) {
      hideLoader();
      toast.error("Failed, Try again or resend otp");
    }
  }

  const handleResendOtp = () => {
    sendOtp(userDataForOtp);
    setOtpNumber("");
  }

  const getProfileData = async () => {
    try {
      showLoader();
      let {
        data: {
          data
        }
      } = await getProfile();
      hideLoader();
      return data;

    } catch (error) {
      hideLoader();
    }
  }
  return (

    <section className={styles.bgMain}>
      <Container className={styles.containerBg} maxwidth="sm">
        <Row>
          <Col md={6} className={styles.padZero}>
            <div className={styles.indiaMap}>
              <img src={LoginImage} alt='' />
              <div className={styles.satCon}>
                <h3>Access<p>your</p>
                  <span>Digital Job Offer</span></h3>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className={styles.bgIndidaMap}>
            {
              !isOtpSent ? (<div className={styles.otpform}>
                <div className={styles.cntntForm}>
                  <img src={CompanyImage} alt='' />
                  <p>Login with your WhatsApp number</p>
                </div>
                <form className={styles.formWidth} onSubmit={handleSubmit(submit)}>
                  <div className={styles.iconSpc}>
                    <img src={whatsappIcon} alt='' />
                    <span></span>
                    <p>+91</p>
                  </div>
                  <input
                    className={styles.formControl}
                    placeholder="WhatsApp Number"
                    {...register(
                      "whatsappMobile", {
                      required: true,
                      pattern: /^[0-9]*$/,
                      maxLength: 10
                    })} />
                  {errors.whatsappMobile && <span className="errorMessage">{message[errors.whatsappMobile.type]}</span>}
                  <div className={styles.btnNew} onClick={handleSubmit(submit)}>
                    <div className={styles.effNew}></div>
                    <a>Send OTP <span></span></a>
                  </div>
                </form>
              </div>
              ) : (

                <div className={styles.otpsubmit}>
                  <div className={styles.cntntForm}>
                    <img src={CompanyImage} alt='' />
                    <p>Please enter the OTP,<br />sent to your Phone Number</p>
                    <span>{mobileNumber}
                      <a onClick={() => setIsOtpSent(!isOtpSent)}><img src={editIcon} alt='' /></a>
                      {/* <a onClick={handleResendOtp}>Resend Otp</a> */}
                    </span>
                  </div>
                  <form className={styles.submitorm} onSubmit={handleSubmit(otpSubmit)}>
                    <div className={styles.inpOtp}>
                      <OtpInput
                        inputStyle={styles.formControl}
                        numInputs={6}
                        isDisabled={false}
                        hasErrored={otpError}
                        errorStyle={styles.otpError}
                        onChange={handleOtpChange}
                        separator={<span />}
                        isInputNum={true}
                        // isInputSecure={isInputSecure}
                        shouldAutoFocus
                        value={otpNumber}
                        placeholder="------"
                      />
                      {otpError && <span className='errorMessage'>Otp numer is required</span>}
                    </div>
                    {/* <p><span>120</span> seconds remaining</p> */}
                    <p>Haven’t receive the code? <span><a className={styles.resendOtp} onClick={handleResendOtp}>Resend Otp</a></span></p>
                    <div className={styles.btnNew} onClick={handleSubmit(otpSubmit)}>
                      <div className={styles.effNew}></div>
                      <a>Submit<span></span></a>
                    </div>
                  </form>
                </div>
              )
            }
            </div>

          </Col>
        </Row>
      </Container>
      <Container maxwidth="sm" className={styles.newFoot}>
        <Row>
          <Col md={12}>
            <div className={styles.footerHead}>
              <p>© 2022 Jobsgaar. All rights reserved.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Login;