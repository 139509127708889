import { Col, Container, Row } from 'react-bootstrap';
import HomeLayout from '../../layouts/HomeLayout/HomeLayout';
import SubHeader from '../common/SubHeader/SubHeader';
import styles from './Dashboard.module.scss';

import { useState, useEffect } from 'react';
import CustomPagination from '../common/CustomPagination/CustomPagination';
import { hideLoader, showLoader } from '../../helpers';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import NoDataFound from '../common/NoDataFound/NoDataFound';

const Dashboard = () => {

    const { path } = useParams();
    const [viewType, setViewType] = useState('listView');


    const handleViewType = (data) => {
        setViewType(data);
    }

    return (
        <HomeLayout>
            {/* <div className='pageContentWrapper'> */}
            <section className='mainContent'>
                {/* <SubHeader handleType={handleViewType} />
                <Container fluid>

                </Container> */}
            </section>
            {/* {jobsData && jobsData.length > 0 && <CustomPagination count={jobsData.length} recordPerPage={recordsCount} page={currentPage} showFirstButton={false} showLastButton={false} changePage={onPageChange} totalRecords={(e) => setRecordsCount(e.target.value)} />
            } */}
            
            {/* </div> */}
        </HomeLayout>
    )
}

export default Dashboard;