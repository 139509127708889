import Routes from './routes/index';
import './App.scss';
import { useEffect, useState } from 'react';

//toaster
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//loader
import { TailSpin } from 'react-loader-spinner';
import PubSub from 'pubsub-js';

function App() {

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchLoader();
  }, []);

  const fetchLoader = () => {
    const loaderResponse = PubSub.subscribe('loader', (msg, data) => {
      setLoading(data)
    });
  }

  return (
    <>
      {
        loading && <div className="loader">
          <TailSpin color="#fff" height={80} width={80} />
        </div>
      }
      <Routes />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='dark'
      />
    </>
  );
}

export default App;
