import styles from './Footer.module.scss';
import { Row, Col, Container } from 'react-bootstrap';

const Footer = () => {
    return (
        <footer className={styles.footerSection}>
           <Container fluid>
                <Row>
                    <Col md={12}>
                        <div className="footer-nav-new">
                            <div className={styles.footerNav}>
                                <ul>
                                    <li><a href="/">About</a></li>
                                    <li><a href="/">Blog</a></li>
                                    <li><a href="/">Support</a></li>
                                    <li><a href="/">Need Help ?</a></li>
                                </ul>
                            </div>
                            <div className={styles.copyRight}>
                                <p>© 2022 Jobsgaar. All rights reserved.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer;