import { axiosInstance } from "../axiosInstance";
import { endPoints } from "../endPoints";

export const login = (data) => {
  return axiosInstance({ method: "post", url: endPoints.common.login, data });
};

export const getAllJobsOffer = (params = {}) => {
  return axiosInstance({ method: "get", url: endPoints.common.getAllJobsOffer, params });
};
export const getProfile = (params = {}) => {
  return axiosInstance({ method: "get", url: endPoints.candidate.getProfile});
};
export const getJobOffers = (params = {}) => {
  return axiosInstance({ method: "get", url: endPoints.externalapi.getJobOffers});
};

export const requestToViewJobOfferDetails = (data) => {
  return axiosInstance({ method: "put", url: endPoints.common.requestToViewJobOfferDetails,data});
};

export const grantToShowJobOfferDetailsWeb = (data) => {
  return axiosInstance({ method: "put", url: endPoints.common.grantToShowJobOfferDetailsWeb,data});
};