import React from "react";
import styles from './Decryptjoboffer.module.scss';
import { Col, Container, FormGroup, Row } from 'react-bootstrap';
import CompanyImg from '../../assets/images/logoNew.svg';
import JobDetail from '../../assets/images/jobDetail.svg';
import offerExp from '../../assets/images/offerExpair.svg';
import officeInfo from '../../assets/images/officeInfo.svg';
import salBon from '../../assets/images/salBon.svg';
import moment from 'moment';


const DecryptComponent = (props) => {
    const { candidateName, candidateNumber, candidateEmail, candidateCity, candidateState, candidateAddress, item } = props;

    return (
        <div className={styles.scrollbarContainer}>
            <div className={styles.scrollContent}>
                <div className={styles.perDetail}>
                    <img className={styles.mediaQuery} src={CompanyImg} alt='' />
                    <h3><span>Name:</span> {candidateName}</h3>
                    <h3><span>Phone number:</span>{candidateNumber}</h3>
                    <h3><span>Email Id:</span> {candidateEmail}</h3>
                    <div className={styles.addreSec}>
                        <h3><span>City:</span> {candidateCity} ({candidateState})</h3>
                        <h3><span>Address:</span> {candidateAddress}</h3>
                    </div>
                    <p>We have all really enjoyed speaking with you and getting to know you over the interactions we had.</p>
                    <p>We are impressed with your background and approach and would love to formally offer you a position at</p>
                    <div className={styles.streetSec}>
                        <h3>{item.companyName}</h3>
                        <h3>{item.companyAddress}</h3>
                    </div>
                </div>

                <div className={styles.jobDetails}>
                    <h2><img src={JobDetail} alt='' />Job Details</h2>
                    <div className={styles.detailNew}>
                        <h3><span>Position name:</span> {item.jobPositionName}</h3>
                        <h3><span>Job title:</span> {item.jobTitle}</h3>
                        <h3><span>Specialzations: </span>
                        </h3>
                        <h3><span>Job Location:</span>{item.jobLocation}</h3>
                        <h3><span>Date of joining:</span> {moment(item?.joiningDate).format("DD MMM'YY")}</h3>
                        <h3><span>Notice period: </span>{item.noticePeriod}</h3>
                    </div>
                </div>

                <div className={styles.offerExp}>
                    <h2><img src={offerExp} alt='' />Offer letter expire</h2>
                    <div className={styles.detailNew}>
                        <h3><span>Expire date:</span> 28th July 2022</h3>
                        <h3><span>Expire time:</span> 09:00 am</h3>
                    </div>
                </div>

                <div className={styles.salBon}>
                    <h2><img src={salBon} alt='' />Salary & Bonus</h2>
                    <div className={styles.detailNew}>
                        <h3><span>Salary (per month):</span>{item.salary}</h3>
                        <h3><span>Bonus period:</span> {item.oneTimeBonus.bonusPeriod}</h3>
                        <h3><span>Bonus amount:</span> {item.oneTimeBonus.amount} </h3>
                    </div>
                </div>

                <div className={styles.offiecInfor}>
                    <h2><img src={officeInfo} alt='' />Office Information</h2>
                    <div className={styles.detailNew}>
                        <h3><span>Job shift:</span>{item.shift}</h3>
                        <h3><span>Job type:</span>{item.shift}</h3>
                        <h3><span>Work from:</span> Work from Office </h3>
                        <h3><span>Shift timing:</span> {item.workingHours.startTime} am to {item.workingHours.endTime} pm</h3>
                        <h3><span>Language:</span> Hindi</h3>
                        <h3><span>Working days:</span>{item.workingDays.monday === true ? 'Monday | ' : ''} {item.workingDays.tuesday === true ? 'Tuesday | ' : ''}  {item.workingDays.wednesday === true ? 'Wednesday | ' : ''}  {item.workingDays.thursday === true ? 'Thursday | ' : ''}  {item.workingDays.monday === true ? 'Friday' : ''} {item.workingDays.saturday === true ? ' | Saturday' : ''} {item.workingDays.sunday === true ? ' | Sunday' : ''}</h3>
                        <h3><span>Requirements:</span> Driving license  |  Motorcycle</h3>
                        <h3><span>Information:</span> {item.comments} </h3>
                        <div className={styles.newCont}>
                            <p>Please note that this is only a provisional offer of employment/professional services and is not being considered as an appointment letter.</p>
                            <p>An appointment letter would be issued to you on your accepting this provisional offer.</p>
                            <p className={styles.weLook}>We look forward to welcoming you on board.</p>
                        </div>
                        <div className={styles.mainAdd}>
                            <h3>{item.employerName} - {item.representativeRole}</h3>
                            <h3>{item.companyName}</h3>
                            <h3>{item.companyAddress}</h3>
                        </div>
                    </div>
                </div>
                <div className={styles.signBox}>
                    <h3>Your signature here<span>Sign in the canvas below</span></h3>
                    <Row>
                        <Col md={8}>
                            <div className={styles.signInt}>
                                <div className={styles.inSign}></div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className={styles.btnsNew}>
                                <button className={styles.subMit}>Submit my Sign</button>
                                <button className={styles.clearCan}>Clear Canvas</button>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className={styles.btnAll}>
                    <button className={styles.rejectHis}>Reject This offer</button>
                    <button className={styles.acceptOff}>Accept this Offer</button>
                </div>
            </div>
        </div>)

}

export { DecryptComponent }