import styles from './Sidebar.module.scss';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import CompanyImage from '../../../assets/images/company-logo.jpeg';

import { Button } from 'react-bootstrap';
import { Collapse } from '@mui/material';
import { PieChart, Pie, Cell } from "recharts";
import {
    CircularProgressbar,
    buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { useEffect, useState } from 'react';
const Sidebar = ({
    userData
}) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState({
        jobs: false,
        candidates: false,
        analytics: false
    });
    const [liveJobsCount, setLiveJobsCount] = useState(10);
    const [pendingJobsCount, setPendingJobsCount] = useState(100);
    const [suspendJobsCount, setSuspendJobsCount] = useState(500);
    const [closedJobsCount, setClosedJobsCount] = useState(200);
    const [minimumValue, setMinimumValue] = useState(0);
    const [maximumValue, setmaximumValue] = useState(1000);
    const [liveJobColor, setLiveJobColor] = useState("#00C49F");
    const [pendingJobColor, setPendingJobColor] = useState("#0088FE");
    const [suspendedJobColor, setSuspendedJobColor] = useState("#FFBB28");
    const [closedJobColor, setClosedJobColor] = useState("#FF8042");
    const [matchedCount, setMatchedCount] = useState(2);
    const [shortlistedCount, setShortlistedCount] = useState(2);
    const [interviewCount, setInterviewCount] = useState(2);
    const [inReviewCount, setInReviewCount] = useState(2);
    const [jobOffersCount, setJobOffersCount] = useState(2);
    const [hiredCount, setHiredCount] = useState(2);
    const [rejectedCount, setRejectedCount] = useState(2);


    const triggerCollapse = (type) => {
        isOpen[type] && isOpen[type] === true ? setIsOpen({ [type]: false }) : setIsOpen({ [type]: true });
        if( type == 'jobs' && !isOpen[type]) {
            navigate('/jobs/live');
        } else if(type === 'candidates' && !isOpen[type]) {
            navigate('/candidates/matched');
        }
    }

    const chartStyle = {
        height: '70%'
    }

    const jobsData = [
        { name: "Live jobs", value: liveJobsCount / 100 },
        { name: "Pending jobs", value: pendingJobsCount / 100 },
        { name: "Suspended jobs", value: suspendJobsCount / 100 },
        { name: "Closed jobs", value: closedJobsCount / 100 }
    ];
    const jobsColors = [liveJobColor, pendingJobColor, suspendedJobColor, closedJobColor];

    const candidatesData = [
        { name: "Live jobs", value: matchedCount / 100 },
        { name: "Pending jobs", value: shortlistedCount / 100 },
        { name: "Suspended jobs", value: interviewCount / 100 },
        { name: "Closed jobs", value: inReviewCount / 100 },
        { name: "Live jobs", value: jobOffersCount / 100 },
        { name: "Pending jobs", value: hiredCount / 100 },
        { name: "Suspended jobs", value: rejectedCount / 100 },
    ];
    const candidatesColors = ["#1ABC9C", "#B0B3B7", "#F1C40F", "#9B59B6", "#F8A5C2", "#FAD390", "#E74C3C"];
    let totalJobsCount = liveJobsCount + suspendJobsCount + pendingJobsCount + closedJobsCount;
    let totalCandidatesCount = matchedCount + shortlistedCount + interviewCount + inReviewCount + jobOffersCount + hiredCount + rejectedCount;

    useEffect(() => {
        let newPath = pathname.split('/');
        (newPath[1] === 'jobs' && newPath[2] === "add") ? setIsOpen({'jobs': false}) : newPath[1] === 'jobs' ? setIsOpen({'jobs': true}) : newPath[1] === 'candidates' ? setIsOpen({'candidates': true}) : setIsOpen({'analytics': true});
    }, [pathname]);

    return (
        <div className={styles.sidebarWrapper}>
            <div className={styles.sidebarBrand}>
                <Link to="/dashboard ">
                    <img src={userData && userData.companyLogoUrl ? userData.companyLogoUrl : CompanyImage} alt='' />
                </Link>
                <h3>{userData.companyName}</h3>
            </div>
            <div className={styles.navNewScroll}>
                <nav className={styles.dashboardNavList}>
                    <div className={styles.dashboardNavDropdown}>
                        <div className={`${styles.navHead} ${isOpen.jobs ? styles.navHeadColor : ""}`} onClick={() => triggerCollapse('jobs')}> Jobs
                            <PieChart width={60} height={50}>
                                <text dy={0} x={29} y={27} textAnchor="middle" dominantBaseline="middle" fontSize="11px" fill="#B0B3B7">
                                    {totalJobsCount}
                                </text>
                                <Pie
                                    data={jobsData}
                                    cx={25}
                                    cy={23}
                                    startAngle={220}
                                    endAngle={-40}
                                    innerRadius={18}
                                    outerRadius={23}
                                    fill="#8884d8"
                                    paddingAngle={0}
                                    dataKey="value"
                                >
                                    {jobsData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={jobsColors[index % jobsColors.length]} />
                                    ))}
                                </Pie>
                            </PieChart>
                        </div>
                        <Collapse in={isOpen.jobs} timeout={1000}>
                            <div>
                                <NavLink to="/jobs/live" className={styles.navItem} style={({ isActive }) => isActive ? { color: "#1ABC9C", background: "#222E4E" } : {}}>
                                    <div><span className={styles.dotStyle} style={{ background: "#1ABC9C" }}></span>Live Jobs</div>
                                    <div className={styles.graph}>
                                        <CircularProgressbar
                                            value={liveJobsCount}
                                            text={liveJobsCount}
                                            circleRatio={0.75}
                                            minValue={minimumValue}
                                            maxValue={maximumValue}
                                            styles={buildStyles({
                                                rotation: 1 / 2 + 1 / 8,
                                                strokeLinecap: "butt",
                                                trailColor: "#eee",
                                                pathColor: liveJobColor,
                                                textColor: liveJobColor,
                                                textSize: "25px"
                                            })}
                                        />
                                    </div>
                                </NavLink>
                                <NavLink to="/jobs/pending" className={styles.navItem} style={({ isActive }) => isActive ? { color: "#3498DB", background: "#222E4E" } : {}}>
                                    <div><span className={styles.dotStyle} style={{ background: "#3498DB" }}></span>Pending</div>
                                    <div className={styles.graph}>
                                        <CircularProgressbar
                                            value={pendingJobsCount}
                                            text={pendingJobsCount}
                                            circleRatio={0.75}
                                            minValue={minimumValue}
                                            maxValue={maximumValue}
                                            styles={buildStyles({
                                                rotation: 1 / 2 + 1 / 8,
                                                strokeLinecap: "butt",
                                                trailColor: "#eee",
                                                pathColor: pendingJobColor,
                                                textColor: pendingJobColor,
                                                textSize: "25px"
                                            })}
                                        />
                                    </div>
                                </NavLink>
                                <NavLink to="/jobs/suspended" className={styles.navItem} style={({ isActive }) => isActive ? { color: "#F1C40F", background: "#222E4E" } : {}}>
                                    <div><span className={styles.dotStyle} style={{ background: "#F1C40F" }}></span>Suspend</div>
                                    <div className={styles.graph}>
                                        <CircularProgressbar
                                            value={suspendJobsCount}
                                            text={suspendJobsCount}
                                            circleRatio={0.75}
                                            minValue={minimumValue}
                                            maxValue={maximumValue}
                                            styles={buildStyles({
                                                rotation: 1 / 2 + 1 / 8,
                                                strokeLinecap: "butt",
                                                trailColor: "#eee",
                                                pathColor: suspendedJobColor,
                                                textColor: suspendedJobColor,
                                                textSize: "25px"
                                            })}
                                        />
                                    </div>
                                </NavLink>
                                <NavLink to="/jobs/closed" className={styles.navItem} style={({ isActive }) => isActive ? { color: "#E74C3C", background: "#222E4E" } : {}}>
                                    <div><span className={styles.dotStyle} style={{ background: "#E74C3C" }}></span>Closed</div>
                                    <div className={styles.graph}>
                                        <CircularProgressbar
                                            value={closedJobsCount}
                                            text={closedJobsCount}
                                            circleRatio={0.75}
                                            minValue={minimumValue}
                                            maxValue={maximumValue}
                                            styles={buildStyles({
                                                rotation: 1 / 2 + 1 / 8,
                                                strokeLinecap: "butt",
                                                trailColor: "#eee",
                                                pathColor: closedJobColor,
                                                textColor: closedJobColor,
                                                textSize: "25px"
                                            })}
                                        />
                                    </div>
                                </NavLink>
                            </div>
                        </Collapse>
                    </div>
                    <div className={styles.dashboardNavDropdown}>
                        <div className={`${styles.navHead} ${isOpen.candidates ? styles.navHeadColor : ""}`} onClick={() => triggerCollapse('candidates')}> Candidates
                            <PieChart width={60} height={50}>
                                <text dy={0} x={29} y={27} textAnchor="middle" dominantBaseline="middle" fontSize="11px" fill="#B0B3B7">
                                    {totalCandidatesCount}
                                </text>
                                <Pie
                                    data={candidatesData}
                                    cx={25}
                                    cy={23}
                                    startAngle={220}
                                    endAngle={-40}
                                    innerRadius={18}
                                    outerRadius={23}
                                    fill="#8884d8"
                                    paddingAngle={0}
                                    dataKey="value"
                                >
                                    {candidatesData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={candidatesColors[index % candidatesColors.length]} />
                                    ))}
                                </Pie>
                            </PieChart>
                        </div>
                        <Collapse in={isOpen.candidates} timeout={1000}>
                            <div>
                                <NavLink to="/candidates/matched" className={styles.navItem} style={({ isActive }) => isActive ? { color: candidatesColors[0], background: "#222E4E" } : {}}>
                                    <div><span className={styles.dotStyle} style={{ background: candidatesColors[0] }}></span>Matched</div>
                                    <div className={styles.graph}>
                                        <CircularProgressbar
                                            value={matchedCount}
                                            text={matchedCount}
                                            circleRatio={0.75}
                                            minValue={minimumValue}
                                            maxValue={maximumValue}
                                            styles={buildStyles({
                                                rotation: 1 / 2 + 1 / 8,
                                                strokeLinecap: "butt",
                                                trailColor: "#eee",
                                                pathColor: candidatesColors[0],
                                                textColor: candidatesColors[0],
                                                textSize: "25px"
                                            })}
                                        />
                                    </div>
                                </NavLink>
                                <NavLink to="/candidates/shortlisted" className={styles.navItem} style={({ isActive }) => isActive ? { color: candidatesColors[1], background: "#222E4E" } : {}}>
                                    <div><span className={styles.dotStyle} style={{ background: candidatesColors[1] }}></span>Shortlisted</div>
                                    <div className={styles.graph}>
                                        <CircularProgressbar
                                            value={shortlistedCount}
                                            text={shortlistedCount}
                                            circleRatio={0.75}
                                            minValue={minimumValue}
                                            maxValue={maximumValue}
                                            styles={buildStyles({
                                                rotation: 1 / 2 + 1 / 8,
                                                strokeLinecap: "butt",
                                                trailColor: "#eee",
                                                pathColor: candidatesColors[1],
                                                textColor: candidatesColors[1],
                                                textSize: "25px"
                                            })}
                                        />
                                    </div>
                                </NavLink>
                                <NavLink to="/candidates/interview" className={styles.navItem} style={({ isActive }) => isActive ? { color: candidatesColors[2], background: "#222E4E" } : {}}>
                                    <div><span className={styles.dotStyle} style={{ background: candidatesColors[2] }}></span>Interview</div>
                                    <div className={styles.graph}>
                                        <CircularProgressbar
                                            value={interviewCount}
                                            text={interviewCount}
                                            circleRatio={0.75}
                                            minValue={minimumValue}
                                            maxValue={maximumValue}
                                            styles={buildStyles({
                                                rotation: 1 / 2 + 1 / 8,
                                                strokeLinecap: "butt",
                                                trailColor: "#eee",
                                                pathColor: candidatesColors[2],
                                                textColor: candidatesColors[2],
                                                textSize: "25px"
                                            })}
                                        />
                                    </div>
                                </NavLink>
                                <NavLink to="/candidates/inreview" className={styles.navItem} style={({ isActive }) => isActive ? { color: candidatesColors[3], background: "#222E4E" } : {}}>
                                    <div><span className={styles.dotStyle} style={{ background: candidatesColors[3] }}></span>In Review</div>
                                    <div className={styles.graph}>
                                        <CircularProgressbar
                                            value={inReviewCount}
                                            text={inReviewCount}
                                            circleRatio={0.75}
                                            minValue={minimumValue}
                                            maxValue={maximumValue}
                                            styles={buildStyles({
                                                rotation: 1 / 2 + 1 / 8,
                                                strokeLinecap: "butt",
                                                trailColor: "#eee",
                                                pathColor: candidatesColors[3],
                                                textColor: candidatesColors[3],
                                                textSize: "25px"
                                            })}
                                        />
                                    </div>
                                </NavLink>
                                <NavLink to="/candidates/joboffers" className={styles.navItem} style={({ isActive }) => isActive ? { color: candidatesColors[4], background: "#222E4E" } : {}}>
                                    <div><span className={styles.dotStyle} style={{ background: candidatesColors[4] }}></span>Job Offers</div>
                                    <div className={styles.graph}>
                                        <CircularProgressbar
                                            value={jobOffersCount}
                                            text={jobOffersCount}
                                            circleRatio={0.75}
                                            minValue={minimumValue}
                                            maxValue={maximumValue}
                                            styles={buildStyles({
                                                rotation: 1 / 2 + 1 / 8,
                                                strokeLinecap: "butt",
                                                trailColor: "#eee",
                                                pathColor: candidatesColors[4],
                                                textColor: candidatesColors[4],
                                                textSize: "25px"
                                            })}
                                        />
                                    </div>
                                </NavLink>
                                <NavLink to="/candidates/hired" className={styles.navItem} style={({ isActive }) => isActive ? { color: candidatesColors[5], background: "#222E4E" } : {}}>
                                    <div><span className={styles.dotStyle} style={{ background: candidatesColors[5] }}></span>Hired</div>
                                    <div className={styles.graph}>
                                        <CircularProgressbar
                                            value={hiredCount}
                                            text={hiredCount}
                                            circleRatio={0.75}
                                            minValue={minimumValue}
                                            maxValue={maximumValue}
                                            styles={buildStyles({
                                                rotation: 1 / 2 + 1 / 8,
                                                strokeLinecap: "butt",
                                                trailColor: "#eee",
                                                pathColor: candidatesColors[5],
                                                textColor: candidatesColors[5],
                                                textSize: "25px"
                                            })}
                                        />
                                    </div>
                                </NavLink>
                                <NavLink to="/candidates/rejected" className={styles.navItem} style={({ isActive }) => isActive ? { color: "#E74C3C", background: "#222E4E" } : {}}>
                                    <div><span className={styles.dotStyle} style={{ background: candidatesColors[6] }}></span>Rejected</div>
                                    <div className={styles.graph}>
                                        <CircularProgressbar
                                            value={rejectedCount}
                                            text={rejectedCount}
                                            circleRatio={0.75}
                                            minValue={minimumValue}
                                            maxValue={maximumValue}
                                            styles={buildStyles({
                                                rotation: 1 / 2 + 1 / 8,
                                                strokeLinecap: "butt",
                                                trailColor: "#eee",
                                                pathColor: candidatesColors[6],
                                                textColor: candidatesColors[6],
                                                textSize: "25px"
                                            })}
                                        />
                                    </div>
                                </NavLink>
                            </div>
                        </Collapse>
                    </div>
                    <div className={styles.btnNew} onClick={()=>navigate('/jobs/add')}>
                        <div className={styles.effNew}></div>
                        <a><FontAwesomeIcon icon={faPlus} /> ADD NEW JOB <span></span></a>
                    </div>
                </nav>

            </div>
        </div>
    )
}

export default Sidebar;