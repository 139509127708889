const baseURL = "https://api.jobsgaar.com";
const employerURL = `${baseURL}/v1/employer`;
const candidateURL = `${baseURL}/v1/candidate`;
const commonURL = `${baseURL}/v1/common`;
const otpURL = `${baseURL}/v1/otp`;
const fileUploadURL = `${baseURL}/v1/upload`;
const baseURLext = "https://apiexternal.jobsgaar.com/api/v1";
export const endPoints = {
 employer: {
     getJobs: `${employerURL}/get/all/jobs/{status}`,
     getProfile: `${employerURL}/get/my/profile`
 },
 common: {
     login: `${commonURL}/signIn`,
     getCandidateByStatus: `${commonURL}/job/status/list`,
     updateCandidateJobStatus: `${commonURL}/update/job/match/action`,
     cityListByState: `${commonURL}/get/statewise/city/list`,
     getExperienceList: `${commonURL}/get/experience/list`,
     getEducationList: `${commonURL}/get/education/list`,
     requestToViewJobOfferDetails:`${commonURL}/update/requestToViewJobOfferDetails`,
     grantToShowJobOfferDetailsWeb:`${commonURL}/update/grantToShowJobOfferDetailsWeb`
 },
 otp: {
     requestOtpforLogin: `${otpURL}/requestOtpForLogin`,
     verifyOtpForLogin: `${otpURL}/verifyOtpForLogin`
 },
 fileUpload: {
     upload: `${fileUploadURL}/file`
 },
 candidate:{
    getProfile: `${candidateURL}/get/my/profile`
 },
 externalapi:{
    getJobOffers:`${baseURLext}/jobOffers/getJobOffers`
    
 }

}