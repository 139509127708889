// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CustomPagination_paginationSection__n5TxK {\n  margin: 20px 0px 0px 20px;\n}\n\n.CustomPagination_content__A3xQT {\n  display: flex;\n  justify-content: space-between;\n}\n.CustomPagination_content__A3xQT div:nth-child(1) {\n  margin-top: 10px;\n}\n.CustomPagination_content__A3xQT div:nth-child(1) span {\n  color: #B0B3B7;\n  font-size: 13px;\n  letter-spacing: 0.03em;\n}\n.CustomPagination_content__A3xQT div:nth-child(1) select {\n  margin: 0px 10px;\n  border-color: #FFFFFF;\n  background: none;\n  color: #ff6600;\n  padding: 1px 10px;\n  border-radius: 5px;\n}\n.CustomPagination_content__A3xQT div:nth-child(1) select option {\n  background-color: #1B2744;\n  border-radius: 5px;\n}", "",{"version":3,"sources":["webpack://./src/components/common/CustomPagination/CustomPagination.module.scss"],"names":[],"mappings":"AAAA;EAEI,yBAAA;AAAJ;;AAGA;EACI,aAAA;EACA,8BAAA;AAAJ;AAEI;EACI,gBAAA;AAAR;AACQ;EACI,cAAA;EACA,eAAA;EACA,sBAAA;AACZ;AAEQ;EACI,gBAAA;EACA,qBAAA;EACA,gBAAA;EACA,cAAA;EACA,iBAAA;EACA,kBAAA;AAAZ;AAEY;EACI,yBAAA;EACA,kBAAA;AAAhB","sourcesContent":[".paginationSection {\n    // height: 30%; \n    margin: 20px 0px 0px 20px;\n}\n\n.content {\n    display: flex;\n    justify-content: space-between;\n\n    div:nth-child(1) {\n        margin-top: 10px;\n        span {\n            color: #B0B3B7;\n            font-size: 13px;\n            letter-spacing: 0.03em;\n        }\n\n        select {\n            margin: 0px 10px;\n            border-color: #FFFFFF;\n            background: none;\n            color: #ff6600;\n            padding: 1px 10px;\n            border-radius: 5px;\n\n            option {\n                background-color: #1B2744;\n                border-radius: 5px;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginationSection": "CustomPagination_paginationSection__n5TxK",
	"content": "CustomPagination_content__A3xQT"
};
export default ___CSS_LOADER_EXPORT___;
