import styles from './NoDataFound.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';

import NoDataImage from '../../../assets/images/data-not.svg';

function NoDataFound({
    description,
    type
}) {
    return (
        <div className={styles.noData}>
            <img src={NoDataImage} alt='' />
            <div className={styles.noDataCont}>
                <h3>No data Available </h3>
                <p>{description}</p>
                {type && type === 'job' && <Button className={styles.addJobButton}>
                    <FontAwesomeIcon icon={faPlus} /> Add new Job
                </Button>
                }
                
            </div>
        </div>
    )
}
export default NoDataFound;