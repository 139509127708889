import { axiosInstance } from "../axiosInstance";
import { endPoints } from "../endPoints";

export const requestOtpForLogin = (data) => {
  return axiosInstance({ method: "post", url: endPoints.otp.requestOtpforLogin, data });
};

export const verifyOtpForLogin = (data) => {
    return axiosInstance({ method: "post", url: endPoints.otp.verifyOtpForLogin, data });
  };

