import Footer from "../../components/common/Footer/Footer";
import Header from "../../components/common/Header/Header";
import Sidebar from "../../components/common/Sidebar/Sidebar";
//css
import styles from './HomeLayout.module.scss';

const HomeLayout = ({ children }) => {
    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData);
    return (
        <>
            <div className={styles.wrapper}>
                <Sidebar userData={userData}/>
                <Header userData={userData}/>
                <div className={styles.pageContentWrapper}>
                    {children}
                </div>
            </div>
            <Footer />
        </>
    )
}

export default HomeLayout;