import { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faBell, faAlignRight, faArrowCircleRight, faArrowRight, faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark, faPencil, faUser, faUsers, faIndianRupeeSign, faAnglesDown, faAngleDown, faAnglesUp, faAngleUp } from '@fortawesome/free-solid-svg-icons';

//styles
import styles from './Header.module.scss';

//images
import CompanyImage from '../../../assets/images/company-logo.jpeg';
import Profile from '../../../assets/images/profile.svg';
import Theme from '../../../assets/images/theme.svg';
import Signout from '../../../assets/images/signout.svg';
import newCandidate from '../../../assets/images/newCandi.svg';
import joboffer from '../../../assets/images/joboffer.svg';
import Logo from '../../../assets/images/logo.svg';
import { clearCookie } from '../../../helpers';
import { toast } from 'react-toastify';
import defaultUser from '../../../assets/images/profile.svg';



const Header = ({
    userData
}) => {
    const navigate = useNavigate();
    let notificationData = [
        {
            title: "Reprehenderit in voluptate",
            data: "01 Mar’22",
            message: "Adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        },
        {
            title: "Reprehenderit in voluptate",
            data: "01 Mar’22",
            message: "Adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        }
    ];
    const [isShowGearOptions, setIsShowGearOptions] = useState(false);
    const [isShowNotification, setIsShowNotification] = useState(false);
    // const [notificationData, setNotificationData] = useState(getNotificationData);

    const trigger = (type) => {
        if(type === 'settings') {
            setIsShowGearOptions(!isShowGearOptions);
            if(isShowNotification) {
                setIsShowNotification(false);
            }
        } else {
            setIsShowNotification(!isShowNotification);
            if(isShowGearOptions) {
                setIsShowGearOptions(false);
            }
        }
    }
    const setRef = useRef(null);
    const notificationRef = useRef(null);

    useEffect(() => {
        document.addEventListener("click", handleSettingsOutside, false);
        document.addEventListener("click", handleNotificationOutside, false);

        return () => {
          document.removeEventListener("click", handleSettingsOutside, false);
        document.addEventListener("click", handleNotificationOutside, false);
        };
      }, []);

      const handleSettingsOutside = event => {
        if (setRef.current && !setRef.current.contains(event.target)) {
            setIsShowGearOptions(false);
        }
      };

      const handleNotificationOutside = event => {
        if (notificationRef.current && !notificationRef.current.contains(event.target)) {
            setIsShowNotification(false);
        }
      };

      const handleLogout = () => {
        clearCookie('token');
        localStorage.removeItem('userData');
        toast.success("Logged out successfully");
        navigate('/login');
      }

    return (
        <section className={styles.headerSection}>
            <nav className='navbar navbar-expand-lg'>
                <Container fluid>
                    <div className={styles.profile}>
                        <img src={userData && userData.representativeProfileUrl ? userData.representativeProfileUrl : defaultUser} alt='' />
                        <h3>Welcome, {userData && userData.representativeName ? userData.representativeName : userData.fullName}<span>{userData && userData.representativeRole ? userData.representativeRole : ""}</span></h3>
                    </div>
                    <div className="collapse navbar-collapse">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        </ul>
                        <div className={`${styles.dropMenu} d-flex`}>
                            <ul>
                                <li>
                                    <div className={styles.settingsMenu}>
                                        <div className={styles.dropBox} onClick={() => trigger('settings')} ref={setRef}>
                                            <FontAwesomeIcon icon={faGear} />
                                        </div>
                                        <ul className={isShowGearOptions ? 'displayBlock' : 'displayNone'}>
                                            <li className="home">
                                                <div className={styles.arrow}></div>
                                                <a>
                                                    <img className="svg" src={Profile} alt='' />
                                                    <span>Edit Profile</span>
                                                </a>
                                            </li>
                                            <li className="messages">
                                                <a>
                                                    <img className="svg" src={Theme} alt='' />
                                                    <span>Theme</span>
                                                </a>
                                            </li>
                                            <li className="settings" onClick={handleLogout}>
                                                <a>
                                                    <img className="svg" src={Signout} alt='' />
                                                    <span>Sign Out</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>

                                <li className={styles.notificationIcon}>
                                    <div className={styles.settingsMenu} ref={notificationRef}>
                                        <div className={styles.dropBox} onClick={() => trigger('notification')} >
                                            <FontAwesomeIcon icon={faBell} />
                                        </div>
                                        <ul className={isShowNotification ? 'displayBlock' : 'displayNone'}>
                                            {notificationData && notificationData.length > 0 && notificationData.map(({ title, date, message }, index) => {
                                                return (
                                                    <li className={styles.notificationList} key={index}>
                                                        {index === 0 && <div className={styles.arrow}></div>}
                                                        <img src={newCandidate} alt='' />
                                                        <div className={styles.notifiText}>
                                                            <span>{date}</span>
                                                            <h3>{title}</h3>
                                                            <p>{message}</p>
                                                        </div>
                                                    </li>
                                                    // <li className={styles.notificationList} key={index}>
                                                    //     {index === 0 && <div className="arrow"></div>}
                                                    //     <h3>{title} <span>{date}</span></h3>
                                                    //     <p>{message}</p>
                                                    // </li>
                                                )

                                            })}
                                            {/* <li className={styles.notificationList}>
                                                <img src={joboffer} alt='' />
                                                <div className={styles.notifiText}>
                                                    <span>27 April 2022</span>
                                                    <h3>Got a new candidate</h3>
                                                    <p>Adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                                                </div>
                                            </li> */}
                                            
                                            <Link className={styles.viewAllNotification} to="#">See All Notification <FontAwesomeIcon icon={faArrowRightLong} /></Link>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <Link to="/"><img src={Logo} alt='' /></Link>
                                </li>

                            </ul>
                        </div>
                    </div>
                </Container>
            </nav>
        </section>
    )
}

export default Header;