import { style } from '@mui/system';
import React, { useState, useEffect } from 'react';
import { Col, Container, FormGroup, Row } from 'react-bootstrap';
import { showLoader, hideLoader, setCookie } from '../../helpers/index';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import moment from 'moment';
import { toast } from 'react-toastify';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import NoDataFound from '../common/NoDataFound/NoDataFound';
import styles from './Decryptjoboffer.module.scss';
import Decryptimage from '../../assets/images/dcrypt.svg';
import JobDetail from '../../assets/images/jobDetail.svg';
import offerExp from '../../assets/images/offerExpair.svg';
import officeInfo from '../../assets/images/officeInfo.svg'
import CompanyImg from '../../assets/images/logoNew.svg'
import salBon from '../../assets/images/salBon.svg';
import { getJobOffers } from '../../services/api/common';
import { getCookie, clearCookie } from "../../helpers/index";
import { DecryptComponent } from "./DecryptComponent"
import { EncryptComponent } from '../Encryptedjoboffer/EncryptComponent';
import EncryptUnlock from '../../assets/images/EncryptUnlock.svg';
import EncryptTimer from '../../assets/images/EncryptTimer.svg';
import Offerempty from '../Offerempty/OfferEmpty';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs() {
    const [value, setValue] = React.useState(getCookie("selectedJOboffer") ? parseInt(getCookie("selectedJOboffer")) : 0);
    const [jobOfferData, setjobOfferData] = useState([]);
    const [candidateName, setcandidateName] = useState();
    const [candidateNumber, setcandidateNumber] = useState();
    const [candidateEmail, setcandidateEmail] = useState();
    const [candidateCity, setcandidateCity] = useState();
    const [candidateState, setcandidateState] = useState();
    const [candidateAddress, setcandidateAddress] = useState();
    const [isExpired, setisExpired] = useState(false);
    const location = useLocation()
    const viewedAt = location.state.viewedAt
    //const viewedAt = "07/03/2023"
    var datec = new Date(viewedAt);
    const minutesToAdd = 30
    const currentDate = new Date(viewedAt);
    const deadline = new Date(currentDate.getTime() + minutesToAdd * 30000);
    console.log(currentDate)
    const navigate = useNavigate();
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const now = new Date().getTime();
    //const [selectedJOboffer] = useState(getCookie("selectedJOboffer") ? getCookie("selectedJOboffer") : "");
    const distance = deadline - now;

    const getTime = () => {
        const time = Date.parse(deadline) - Date.now();

        if (time < 0) {
            setisExpired(true)
            navigate("/encryptedoffer", { replace: true });

        }
        else {
            setMinutes(Math.floor((time / 1000 / 60) % 60));
            setSeconds(Math.floor((time / 1000) % 60));
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            getTime(deadline);
        }
            , 1000);

        return () => clearInterval(interval);

    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        candidateProfile()
    }, [])
    const candidateProfile = async () => {
        try {
            showLoader();
            const {
                data
            } = await getJobOffers();
            setcandidateName(data.data.fullName)
            setcandidateNumber(data.data.whatsappMobile)
            setcandidateEmail(data.data.email)
            setcandidateCity(data.data.city)
            setcandidateState(data.data.state)
            setcandidateAddress(data.data.fullAddress)
            setjobOfferData(data.data.jobOfferData)
            //    console.log(setjobOfferData)
            toast.success("Job offers fetched successfully");
            //clearCookie("selectedJOboffer")
            hideLoader();
        } catch (error) {
            hideLoader();
            if (error && error.data && error.data.appStatus && error.data.appStatus === 872) {
                window.location.href = "/";
            } else {
                toast.error("Failed");
            }
        }
    }

    const setselectedVal = (value) => {
        setCookie("selectedJOboffer", value)
        navigate("/encryptedoffer", { state: { fromDecrypt: true } });
    }

    // const ShowTimer = (index, item) => {
    //     return (<section className={styles.headfixed} style={{ height: "90px" }}>
    //         <Container fluid className={styles.newPad}>
    //             <div className="row">
    //                 <Col md={12}>
    //                     <header className="styles.headerNavebar">
    //                         <Container>
    //                             <div className={styles.navbar}>
    //                                 <a className={styles.navbarLogo} href>
    //                                     <img className={styles.mediaQuery} src={CompanyImg} alt='' />
    //                                 </a>
    //                                 <ul className={styles.navbarLinks}>
    //                                     {parseInt(getCookie("selectedJOboffer")) === index && <li>
    //                                         <img style={{ height: "20px", width: "20px" }} rel="shortcut icon" src={EncryptTimer} />
    //                                         Auto lock in:
    //                                         <a className={styles.navbarLogo} href>
    //                                             {minutes < 10 ? "0" + minutes : minutes}:{seconds < 10 ? "0" + seconds : seconds} mins
    //                                         </a>
    //                                     </li>}
    //                                     {(jobOfferData.length > 0 && parseInt(getCookie("selectedJOboffer")) !== index) && <li>
    //                                         {/* <button className={styles.showbtn}>Show Job Offer</button> */}

    //                                         <a onClick={() => setselectedVal()} className={styles.showbtn} style={{ textAlign: "center" }}>
    //                                             UNLOCK
    //                                             <br></br>
    //                                             <span>{item.jobTitle}</span>
    //                                         </a>
    //                                     </li>}
    //                                 </ul>
    //                             </div>
    //                         </Container>
    //                     </header>
    //                 </Col>
    //             </div>
    //         </Container>
    //     </section>)
    //}

    // console.log(location.state)
    return (
        <div className={styles.bodyNew}>
            {jobOfferData.length !== 0 && <section className={styles.jobOfferTab}>
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <div className={styles.offerdis}>
                                <Box sx={{ width: '100%' }}>
                                    <Box className={styles.tabCon} sx={{ borderBottom: 1, borderColor: 'divider' }}>

                                        <Tabs className={styles.tabView} value={value} onChange={handleChange} aria-label="basic tabs example"
                                            variant="scrollable"
                                            scrollButtons
                                            allowScrollButtonsMobile>
                                            {/* <Tab className={styles.newList} label="Offer Letter 1" {...a11yProps(0)} />
                                        <Tab className={styles.newList} label="Offer Letter 2" {...a11yProps(1)} />
                                        <Tab className={styles.newList} label="Offer Letter 3" {...a11yProps(2)} />
                                        <Tab className={styles.newList} label="Offer Letter 4" {...a11yProps(1)} />
                                        <Tab className={styles.newList} label="Offer Letter 5" {...a11yProps(2)} />
                                        <Tab className={styles.newList} label="Offer Letter 6" {...a11yProps(1)} />
                                        <Tab className={styles.newList} label="Offer Letter 7" {...a11yProps(2)} /> */}
                                            {jobOfferData.map((item, index) => {
                                                return (
                                                    <Tab
                                                        key={index}
                                                        className={`${styles.newList} ${value === index ? styles.newListActive : ""}`}
                                                        label={item.jobTitle} {...a11yProps(index)}
                                                        icon={parseInt(getCookie("selectedJOboffer")) === index ? <img style={{ height: "20px", width: "20px" }} rel="shortcut icon" src={EncryptUnlock} /> : ""}
                                                    />
                                                )

                                            })}
                                        </Tabs>

                                    </Box>
                                </Box>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>}
            {jobOfferData && jobOfferData.length > 0 ? jobOfferData.map((item, index) => {
                return <TabPanel
                    value={value}
                    index={index}
                    key={index}
                >
                    <section className={styles.headfixed} style={{ height: "90px" }}>
                        <Container fluid className={styles.newPad}>
                            <div className="row">
                                <Col md={12}>
                                    <header className={styles.headerNavebar}>
                                        <Container fluid>
                                            <div className={styles.navbar}>
                                                <a className={styles.navbarLogo} href>
                                                    <img className={styles.mediaQuery} src={CompanyImg} alt='' />
                                                </a>
                                                <ul className={styles.navbarLinks}>
                                                    {parseInt(getCookie("selectedJOboffer")) === index && <li>
                                                        <div className={styles.timerLock}>
                                                            <img rel="shortcut icon" src={EncryptTimer} />
                                                            Auto lock in:
                                                            <a className={styles.navbarLogo} href>
                                                                {minutes < 10 ? "0" + minutes : minutes}:{seconds < 10 ? "0" + seconds : seconds} mins
                                                            </a>
                                                        </div>
                                                    </li>}
                                                    {(jobOfferData.length > 0 && parseInt(getCookie("selectedJOboffer")) !== index) && <li>

                                                        <a href='javascript:void(0);' className={styles.showbtn} style={{ textAlign: "center" }} onClick={() => setselectedVal(index)}>
                                                            UNLOCK
                                                            <br></br>
                                                            <span>{item.jobTitle}</span>
                                                        </a>
                                                    </li>}
                                                </ul>
                                            </div>
                                        </Container>
                                    </header>
                                </Col>
                            </div>
                        </Container>
                    </section>
                    <section className={styles.jobOffer} style={{ marginTop: "100px" }}>
                        <Container>
                            <Row>
                                <Col md={12}>
                                    <div className={styles.offerdis}>
                                        <Box sx={{ width: '100%' }}>
                                            {parseInt(getCookie("selectedJOboffer")) === value ?
                                                <DecryptComponent jobOfferData={jobOfferData} value={value} TabPanel={TabPanel} candidateName={candidateName}
                                                    candidateNumber={candidateNumber} candidateEmail={candidateEmail} candidateProfile={candidateProfile} candidateAddress={candidateAddress} candidateCity={candidateCity} candidateState={candidateState} item={item} index={index} />

                                                :
                                                <EncryptComponent jobOfferData={jobOfferData} value={value} TabPanel={TabPanel} candidateName={candidateName}
                                                    candidateNumber={candidateNumber} candidateEmail={candidateEmail} candidateProfile={candidateProfile}
                                                    candidateAddress={candidateAddress} candidateCity={candidateCity} candidateState={candidateState} item={item}
                                                    index={index} />

                                            }

                                        </Box>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </TabPanel>
            }) : <Offerempty />}






            <section className={styles.footerHead}>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className={styles.footerPera}>
                                <ul>
                                    <li><a target="_blank" href="https://www.jobsgaar.com/">Home</a></li>
                                    <li><a target="_blank" href="https://www.jobsgaar.com/about_hindi">About Us</a></li>
                                    <li><a target="_blank" href="https://jobsgaar.com/blog/">Blogs</a></li>
                                    <li><a target="_blank" href="https://www.jobsgaar.com/contact">Contact Us</a></li>
                                </ul>
                                <p>Powered by  <a className="navbar__logo" href="https://www.jobsgaar.com/"><img src={CompanyImg} /></a></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    );
}