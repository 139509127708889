import { style } from '@mui/system';
import React, { useState, useEffect } from 'react';
import { Col, Container, FormGroup, Row } from 'react-bootstrap';
import styles from './OfferAccessDetails.module.scss';
import encryptImage from '../../assets/images/encryptResume.jpg';
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getCookie } from '../../helpers/';
import { toast } from 'react-toastify';
import axios from 'axios'
import { showLoader, hideLoader, setCookie, clearCookie } from '../../helpers/index';
import { requestToViewJobOfferDetails } from '../../services/api/common';


function OfferAccessDetails() {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [ip, setIP] = useState('');
  const token = getCookie("token");

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    console.log(res.data);
    setIP(res.data.IPv4)
  }
  useEffect(() => {
    //passing getData method to the lifecycle method
    getData()
  }, [])

  //Messages for errors
  let message = {
    required: 'Whatsapp number is required',
    pattern: 'Name should contain only numbers',
    maxLength: "Maximum allowed numbers 10"
  }

  let emailmessage = {
    required: 'Email is required',
    pattern: 'Email should me in proper Format',

  }

  let namemessage = {
    required: 'Name is required',
    pattern: 'Name should contain only numbers',
    maxLength: "Maximum allowed numbers 10"
  }
  const location = useLocation()
  let navigate = useNavigate();
  const value = location.state.value;
  const submit = async (submitData) => {
    //Generating Random Number

    // 👇️ get number between min (inclusive) and max (inclusive)
    const randomNumber = Math.floor(Math.random() * (99999999999 - 11111111111 + 1)) + 99999999999;

    const sendData = {
      byName: submitData.name,
      byPhone: submitData.whatsappMobile,
      byEmail: submitData.email,
      sessionUniqueID: token,
      byIpAddress: ip,
      whatsappIdentification: randomNumber.toString(),
      candidateId: location.state.candidateId,
      jobId: location.state.jobId,
      jobOfferId: location.state.jobofferId,
      employerId: location.state.employerId
    }

    try {
      showLoader();
      const {
        data: {
          data, status
        }
      } = await requestToViewJobOfferDetails(sendData);
      if (status == 200 && data.whatsappIdentification === sendData.whatsappIdentification) {
        hideLoader();
        setCookie("selectedJOboffer", value)
        navigate('/encryptedoffer', {
          state: {
            whatsappIdentification: data.whatsappIdentification,
            sessionUniqueID: token, candidateId: location.state.candidateId,
            jobId: location.state.jobId,
            jobOfferId: location.state.jobofferId,
            employerId: location.state.employerId
          }
        })


      }

    } catch (errors) {
      clearCookie("selectedJOboffer")
      hideLoader();
      toast.error("failed");
    }
  }
  return (

    <section className={styles.bgMain}>
      <Container className={styles.containerBg} maxwidth="sm">
        <Row>
          <Col md={5} className={styles.padNewRes}>
            <div className={styles.otpform}>
              <div className={styles.cntntForm}>
                <h3>Hello,</h3>
                <p>Access the <span>'डिजिटल'</span> Offer Letter here!</p>
              </div>
              <form className={styles.formWidth} onSubmit={handleSubmit(submit)}>
                <div className={styles.FormGroup}>

                  <input
                    className={styles.formControl}
                    placeholder="Your Name"
                    {...register(
                      "name", {
                      required: true
                    })}

                  />
                  {errors.name && <span className="errorMessage">{namemessage[errors.name.type]}</span>}
                </div>
                <div className={styles.FormGroup}>

                  <input
                    className={styles.formControl}
                    placeholder="Email Address"

                    {...register(
                      "email", {
                      required: true,
                      pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/
                    })}

                  />
                  {errors.email && <span className="errorMessage">{emailmessage[errors.email.type]}</span>}
                </div>
                <div className={styles.FormGroup}>

                  <input
                    className={styles.formControl}
                    placeholder="WhatsApp Number (10 digit)"

                    {...register(
                      "whatsappMobile", {
                      required: true,
                      pattern: /^[0-9]*$/,
                      maxLength: 10
                    })}
                  />
                  {errors.whatsappMobile && <span className="errorMessage">{message[errors.whatsappMobile.type]}</span>}
                </div>

                {/* <span className="errorMessage">wrong No</span> */}
                <div className={styles.btnNew} onClick={handleSubmit(submit)}>
                  <div className={styles.effNew}></div>
                  <a>Submit <span></span></a>
                </div>
              </form>
            </div>
          </Col>
          <Col md={7} className={styles.padZero}>
            <div className={styles.indiaMap}>
              <h3>Welcome to India's first digital profile for job seekers &amp; employers. </h3>
              <h1>100% Safe &amp; Reliable. </h1>
              <img class={styles.imageenc} src={encryptImage} alt="" />
            </div>
          </Col>
          <Col md={5} className={styles.padNew}>
            <div className={styles.otpform}>
              <div className={styles.cntntForm}>
                <h3>Hello,</h3>
                <p>Access the <span>'डिजिटल'</span> Offer Letter here!</p>
              </div>
              <form className={styles.formWidth} onSubmit={handleSubmit(submit)}>
                <div className={styles.FormGroup}>

                  <input
                    className={styles.formControl}
                    placeholder="Your Name"
                    {...register(
                      "name", {
                      required: true
                    })}

                  />
                  {errors.name && <span className="errorMessage">{namemessage[errors.name.type]}</span>}
                </div>
                <div className={styles.FormGroup}>

                  <input
                    className={styles.formControl}
                    placeholder="Email Address"

                    {...register(
                      "email", {
                      required: true,
                      pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/
                    })}

                  />
                  {errors.email && <span className="errorMessage">{emailmessage[errors.email.type]}</span>}
                </div>
                <div className={styles.FormGroup}>

                  <input
                    className={styles.formControl}
                    placeholder="WhatsApp Number (10 digit)"

                    {...register(
                      "whatsappMobile", {
                      required: true,
                      pattern: /^[0-9]*$/,
                      maxLength: 10
                    })}
                  />
                  {errors.whatsappMobile && <span className="errorMessage">{message[errors.whatsappMobile.type]}</span>}
                </div>

                {/* <span className="errorMessage">wrong No</span> */}
                <div className={styles.btnNew} onClick={handleSubmit(submit)}>
                  <div className={styles.effNew}></div>
                  <a>Submit <span></span></a>
                </div>
              </form>
            </div>
          </Col>
          <Col md={7} className={styles.padZeroRes}>
            <div className={styles.indiaMap}>
              <div className={styles.haedName}>
                <h3>Welcome to India's first digital profile for job seekers &amp; employers. </h3>
                <h1>100% Safe &amp; Reliable. </h1>
              </div>

              <img class={styles.imageenc} src={encryptImage} alt="" />
            </div>
          </Col>
        </Row>
      </Container>
      <Container maxwidth="sm" className={styles.newFoot}>
        <Row>
          <Col md={12}>
            <div className={styles.footerHead}>
              <p>© 2022 Jobsgaar. All rights reserved.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default OfferAccessDetails;