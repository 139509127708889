// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HomeLayout_wrapper__AKSdI {\n  transition: all 0.5s ease;\n  background: #121931;\n  padding: 15px;\n  height: 100vh;\n  position: relative;\n}\n\n.HomeLayout_pageContentWrapper__KwHx3 {\n  width: 100%;\n  position: absolute;\n  padding: 15px;\n}\n\n@media (min-width: 768px) {\n  .HomeLayout_wrapper__AKSdI {\n    padding-left: 300px;\n  }\n  .HomeLayout_pageContentWrapper__KwHx3 {\n    padding: 20px;\n    position: relative;\n  }\n}\n@media screen and (max-width: 1440px) {\n  .HomeLayout_wrapper__AKSdI {\n    padding-left: 210px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/layouts/HomeLayout/HomeLayout.module.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,mBAAA;EACA,aAAA;EACA,aAAA;EACA,kBAAA;AACJ;;AAEA;EACI,WAAA;EACA,kBAAA;EACA,aAAA;AACJ;;AAUA;EACI;IACI,mBAAA;EAPN;EAUE;IACI,aAAA;IACA,kBAAA;EARN;AACF;AAWA;EACI;IACI,mBAAA;EATN;AACF","sourcesContent":[".wrapper {\n    transition: all 0.5s ease;\n    background: #121931;\n    padding: 15px;\n    height: 100vh;\n    position: relative;\n}\n\n.pageContentWrapper {\n    width: 100%;\n    position: absolute;\n    padding: 15px;\n    // height: 100%;\n}\n\n// .mainContent {\n//     background: #1B2744;\n//     border-radius: 10px;\n//     padding: 15px;\n//     margin-left: 20px;\n// }\n\n@media (min-width: 768px) {\n    .wrapper {\n        padding-left: 300px;\n    }\n\n    .pageContentWrapper {\n        padding: 20px;\n        position: relative;\n    }\n}\n\n@media screen and (max-width: 1440px) {\n    .wrapper {\n        padding-left: 210px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "HomeLayout_wrapper__AKSdI",
	"pageContentWrapper": "HomeLayout_pageContentWrapper__KwHx3"
};
export default ___CSS_LOADER_EXPORT___;
