import { style } from '@mui/system';
import { Col, Container, Row } from 'react-bootstrap';
import CompanyImg from '../../assets/images/logoNew.svg';
import noData from '../../assets/images/nooffer.svg';
import styles from './OfferEmpty.module.scss';

import { useState, useEffect } from 'react';
import { hideLoader, showLoader } from '../../helpers';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

const Offerempty = () => {

    const { path } = useParams();

    return (
        <div className={styles.bodyNew}>
            <section className={styles.headfixed}>
                <Container fluid className={styles.newPad}>
                    <div className="row">
                        <Col md={12}>
                            <header className={styles.headerNavebar}>
                                <Container>
                                    <div className={styles.navbar}>
                                        <a className={styles.navbarLogo} href="https://www.jobsgaar.com/">
                                            <img className={styles.mediaQuery} src={CompanyImg} alt='' />
                                        </a>
                                    </div>
                                </Container>
                            </header>
                        </Col>
                    </div>
                </Container>
            </section>

            <section className={styles.noDataFound}>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className={styles.datanotfound}>
                                <div className={styles.nodata}>
                                    <img src={noData} />
                                    <h3>No offer letter arrived yet</h3>
                                    <p><a href="/encryptedoffer" style={{ textDecoration: "auto" }}>Try again later</a></p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className={styles.footerHead}>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className={styles.footerPera}>
                                <ul>
                                    <li><a target="_blank" href="https://www.jobsgaar.com/">Home</a></li>
                                    <li><a target="_blank" href="https://www.jobsgaar.com/about_hindi">About Us</a></li>
                                    <li><a target="_blank" href="https://jobsgaar.com/blog/">Blogs</a></li>
                                    <li><a target="_blank" href="https://www.jobsgaar.com/contact">Contact Us</a></li>
                                </ul>
                                <p>Powered by  <a className="navbar__logo" href="https://www.jobsgaar.com/"><img src={CompanyImg} /></a></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>

    )
}

export default Offerempty;